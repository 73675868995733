<template>
    <div>
        <b-row class="align-items-center mb-3">
            <b-col cols="8">
                 <b-row class="align-items-center ml-1 mr-1">
                    <b-col>
                        <div style="font-size: 24px"><span class="app-local-location" v-on:click="openCard(1)">{{profile.hour_data.location_name}}</span>   -  <span class="app-local-location" v-on:click="openCard(2)">{{ profile.hour_data.department_name}}</span></div>
                        <div style="font-size: 16px">{{profile.hour_data.client_name}}</div>                       
                    </b-col>

                    <b-col cols="auto" end>
                        <div style="font-size: 14px;  font-weight: 600; text-align:right">
                            <span v-bind:class="{ dotActive: profile.hour_data.deleted == 0, dotInactive: profile.hour_data.deleted == 1 }"> </span> {{profile.hour_data.status_name}} <br>
                            <span class="font-weight-300">{{profile.hour_data.location_street}} {{profile.hour_data.location_house_number}}, {{profile.hour_data.location_city}}</span>   
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="4">
                <b-row class="align-items-center ml-1 mr-1">
                    <b-col>
                        <span> {{ profile.hour_data.hour_date_from }} </span><br>
                        <span class="font-weight-300"> {{ getDayNameAndWeekIndex() }}</span>
                    </b-col>
                    <b-col cols="auto" end>
                        <div class="app-local-time" v-html="getHours()"></div>
                        <div class="font-weight-300 text-right">{{ profile.hour_data.daytime_name }}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        
    </div>
</template>
<script>

import moment from "moment";
export default {
    
    props:["profile"],

    mounted(){
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }, 


    },

    data(){
        return{

        }
    },

    methods: {

        getDayNameAndWeekIndex() {
            moment.locale('nl') 
            return moment(this.profile.hour_data.date_from).format('dddd') + ", wk " + moment(this.profile.hour_data.date_from).format('ww');
        },

        getHours() { 
            
            var date_from = new Date(this.profile.hour_data.date_from);
            var date_to = new Date(this.profile.hour_data.date_to);

            return date_from.getHours() + '<sup class="ml-1">' + date_from.getMinutes().toString().padStart(2, '0') + '</sup> - ' +
                   date_to.getHours() + '<sup class="ml-1">' + date_to.getMinutes().toString().padStart(2, '0') + '</sup> ';
        },

        openCard(type){
            if(type == 1){
                this.$router.push({ name: "clients-profile-location", params: { id_location_data_main: this.profile.hour_data.id_location_data_main}  });
            }else if(type == 2){
                this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: this.profile.hour_data.id_department_data_main}  });
            }
        }
    }

};
</script>

<style scoped>
.app-local-time {
    font-size: 20px;  
    font-weight: 600; 
    text-align:right
}

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.app-local-location{
    cursor: pointer;
}
.app-local-location:hover{
    text-decoration: underline;
}
</style>

<template>
    <div>

        <b-card class="mb-3" v-if="profile.hour_data.id_hour_const_status == 1"><!-- New -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">Bevestigen declaratie diensten en versturen naar de klant.</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirm()" v-if="userRole < 3 || profile.can_user_edit">{{trans('sh-confirm-hours',220)}}</app-button>   
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.hour_data.id_hour_const_status == 2"><!-- Klant validatie -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">Bevestigen declaratie diensten door klant.</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirmByClient()" :loading="confirm_by_client_loading" v-if="userRole < 3 || profile.can_user_edit">Bevestigd door klant</app-button>
                        <app-button type="secondary-grey" @click="rejectByClient()" :disabled="confirm_by_client_loading" v-if="userRole < 3 || profile.can_user_edit">Afwijzen door klant</app-button>
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.hour_data.id_hour_const_status == 3"><!-- Klant akkord -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <!--<span class="text-small">Bevestigen declaratie diensten door klant.</span>-->                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <!--<app-button type="primary" @click="confirmByClient()" v-if="userRole < 3 || profile.can_user_edit">Bevestigd door klant</app-button>-->
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <b-card class="mb-3" v-if="profile.hour_data.id_hour_const_status == 4"><!-- Klant afgewezen -->
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3"></b-icon>                   
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">Klant afwijzing de declaratie.</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="confirm()" v-if="userRole < 3 || profile.can_user_edit">{{trans('sh-confirm-hours',220)}}</app-button> 
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <hours-profile-confirm v-if="mConfirmHours.show" :show.sync="mConfirmHours.show" :result.sync="mConfirmHours.result" :args.sync="mConfirmHours.args" :return.sync="cProfile"/> 
        <hours-profile-reject-by-client v-if="mRejectByClient.show" :show.sync="mRejectByClient.show" :result.sync="mRejectByClient.result" :args.sync="mRejectByClient.args" :return.sync="cProfile"/> 

    </div>
</template>
<script>
import axios from "@axios";
import moment from "moment";
import HoursProfileConfirm from "@app/Planning/Hours/Profile/HoursProfileConfirm.vue";
import HoursProfileRejectByClient from "@app/Planning/Hours/Profile/HoursProfileRejectByClient.vue";
import AppAlerts from '@core/scripts/AppAlerts';


export default {
    components: {        
        HoursProfileConfirm,
        HoursProfileRejectByClient
    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },
    
    watch: {

    },

    computed: {

        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        } 
    
    },

    data() {
        return {
            loading: false,
            confirm_by_client_loading: false,
            reject_by_client_loading: false,
            alert_class : new AppAlerts(),
            userRole: null,
            mConfirmHours: { 
                show: false, args: {
                                    id_shift_data_main: null,
                                    id_hour_data_main: null,
                                    time_from: null,
                                    time_to: null,
                                    person_description: null,
                                    client_description: null,
                                    hours_json: null,
                                    pause: null,
                                    return_type: null
                                }, 
                result: 0, return: null },

            mRejectByClient:{
                show: false, args: {
                                    id_shift_data_main: null,
                                    id_hour_data_main: null,
                                    return_type: null
                                }, 
                result: 0, return: null
            }
        };
    },

    methods: {

        confirm(){
            
            this.mConfirmHours.args.id_hour_data_main = this.profile.id;
            this.mConfirmHours.args.time_from = this.profile.hour_data.real_time_from;
            this.mConfirmHours.args.time_to = this.profile.hour_data.real_time_to;
            this.mConfirmHours.args.person_description = this.profile.hour_data.person_description ? this.profile.hour_data.person_description : '';
            this.mConfirmHours.args.client_description = this.profile.hour_data.client_description ? this.profile.hour_data.client_description : '';
            this.mConfirmHours.args.hours_json = JSON.parse(this.profile.hour_data.hours_json);
            this.mConfirmHours.args.pause = this.profile.hour_data.pause_string;
            this.mConfirmHours.args.return_type = "hour_profile";
            this.mConfirmHours.show = true;
        },

        confirmByClient(){

            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

                if(res == true){

                    this.confirm_by_client_loading = true;

                    axios
                        .post("planning/hours/confirmByClient", {
                            id_hour_data_main: this.profile.id,
                            return_type: "hour_profile"
                        })
                        .then((res) => {                 
                            this.cProfile.hour_data = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cProfile.history.items.refresh ++;
                            this.confirm_by_client_loading = false;
                        });
                }

            });

        },

        rejectByClient(){
            this.mRejectByClient.args.id_hour_data_main = this.profile.id;
            this.mRejectByClient.args.return_type = "hour_profile";
            this.mRejectByClient.show = true;
        }
        

    }
 
};
</script>

<style>
.dropdown-item{
    font-weight: 400 !important;
}
</style>
<style scoped>
.app-button-multi-local{
    background-color: #fff !important;
    border-color: #dee2e6 !important;
    color: #174066 !important;
    border-radius: 50rem !important;
    min-height: 34px !important;
}
</style>
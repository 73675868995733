<template>
    <div>
        <b-card no-body class="app-card-profile"> 
            <b-row>
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('flexworker',182)}}
                    </div>
                </b-col>              
            </b-row>
            <b-row class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right>
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                    </template>
                    <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: profile.hour_data.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>
                </b-dropdown>
            </b-row> 

            <b-row class="mb-3"> 
                <b-col cols="4">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="h-100 d-flex align-items-center ml-auto">
                            <img class="rounded-circle custom-avatar" v-if="profile.hour_data.avatar != null" :src="profile.hour_data.avatar" alt="Avatar" />
                            <img class="rounded-circle custom-avatar" v-else-if="profile.hour_data.sex == 2" src="@/assets/images/avatars/female.jpg" alt="Avatar" />
                            <img class="rounded-circle custom-avatar" v-else  src="@/assets/images/avatars/male.jpg" alt="Avatar" /> 
                        </div>
                    </div>    
                </b-col>
                <b-col cols="6">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="h-100 d-flex align-items-center">
                            
                            <div class="align-items-center">
                                
                                <b-row>
                                    <b-col>
                                        <div class="d-flex align-items-center">
                                            <h4 class="m-0 mr-2">{{profile.hour_data.person_name}}</h4>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col end sm="12" lg="auto">    
                                        <span class="app-local-person-data"> {{profile.hour_data.person_function_name}}</span>
                                    </b-col>
                                </b-row>
                                
                            </div>
                        </div>
                    </div>         
                </b-col>
            </b-row>

            <b-row > 
                <b-col cols="4">
                    <p class="col-form-label-ellipsis">{{trans('phone-number',182)}}</p>
                    <p class="col-form-label-ellipsis">{{trans('email',182)}}</p>
                    <p class="col-form-label-ellipsis mb-3">{{trans('planning-distance',209)}}</p>                                
                </b-col>

                <b-col cols="8">
                    <p class="col-form-value-ellipsis">
                        <b-link :href="'tel:' + phone" v-if="phone"> {{ phone }} </b-link>
                        <span v-else> - </span>  
                    </p>

                    <p class="col-form-value-ellipsis">
                         <b-link :href="'mailto:' + this.profile.hour_data.person_email" v-if="this.profile.hour_data.person_email"> {{ this.profile.hour_data.person_email }} </b-link>
                        <span v-else> - </span>                                        
                    </p>
                   
                    <p class="col-form-value-ellipsis mb-3">{{ profile.hour_data.person_distance ? profile.hour_data.person_distance + ' km' : '-'}}</p>
                </b-col>
            </b-row>
        </b-card>

    </div>
</template>
<script>

export default {

    props:["profile"],

    computed: {
        phone: function () {
            if (this.profile.hour_data.person_phone_number !== null && this.profile.hour_data.person_phone_prefix == '+31') {
                return 0 + this.profile.hour_data.person_phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.profile.hour_data.person_phone_prefix + " " + this.profile.hour_data.person_phone_number;
            }
        }
    },

    data(){
        return{
        }
    },

    methods: {

    }

};
</script>

<style scoped>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
    font-size: 14px !important;
}

.app-local-text{
    color:#174066;
    font-size: 12px !important;
}

.custom-avatar{
    height: 52px;
    width: 52px;
}
</style>
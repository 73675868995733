<template>
    <div v-if="profile !== null" class="app-navbar-profile-content">
        <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row>
                    <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
                    <b-col end class="app-navbar-profile-margin-30">
                        <div class="d-flex justify-content-end align-items-center">
                          <div class="font-weight-800 mr-4 mt-1"> {{trans('core-hour-profile', 271)}} </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col> 
                    <hours-profile-status-bar :profile.sync="profile" />
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>

        <div>
            <b-row>
              <b-col md="12" lg="8" class="pr-lg-2">
                    <hours-profile-action-points-component :profile.sync="profile" />
                    <hours-profile-informations-component class="mb-3" :profile.sync ="profile" />

                    <!--Collapses -->
                    <app-collapse accordion type="margin" >

                      <b-card no-body class="app-card-profile">
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('documents',3)" :id="'documents'">
                               <hours-profile-documents-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>

                      <b-card no-body class="app-card-profile" >
                          <app-collapse-item style="box-shadow:none !important;" :title="trans('sh-activity-log',215)" :id="'shift-history'">
                              <hours-profile-history-component :profile.sync="profile" />
                          </app-collapse-item>
                      </b-card>


                    </app-collapse>
                </b-col>

                <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                     <hours-profile-flexworker-component v-if="profile.hour_data.id_person_data_main != null" class="mb-3" :profile.sync ="profile" />
                     <notes-component :profile.sync="profile"/>
                 
                </b-col>
            </b-row>    
        </div>

    </div>
</template>

<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";

import HoursProfileStatusBar from './StatusBar/HoursProfileStatusBarComponent.vue';
import HoursProfileActionPointsComponent from './ActionPoints/HoursProfileActionPointsComponent.vue';
import HoursProfileFlexworkerComponent from './Flexworker/HoursProfileFlexworkerComponent.vue';
import HoursProfileInformationsComponent from './Informations/HoursProfileInformationsComponent.vue';
import HoursProfileDocumentsComponent from './Documents/HoursProfileDocumentsComponent.vue'
import HoursProfileHistoryComponent from './History/HoursProfileHistoryComponent.vue';
import NotesComponent from '@app/Notes/NotesComponent.vue';


export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    HoursProfileStatusBar,
    HoursProfileActionPointsComponent,
    HoursProfileFlexworkerComponent,
    HoursProfileInformationsComponent,
    HoursProfileDocumentsComponent,
    HoursProfileHistoryComponent,
    NotesComponent,

  },

  created() {
    this.getData();
  },

  mounted() {},

  computed : {

      
  },

  watch: {


  },

  data(){
      return {
      
              id_hour_data_main: this.$route.params.id_hour_data_main,
              profile: null,
              active_tab: 0,
          }

  },

  methods: {

    getData(){
      axios
        .get("planning/hours/getProfile/" + this.id_hour_data_main)
        .then((res) => {
            if(res.data != null){
              this.profile = res.data;  
            }            
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    refreshTab(e){
      if (this.activeTab !== e) {
        this.activeTab = e;
      
        if(e == 0){//Registration
          //this.profile.registration.items.refresh ++;
        }else if(e == 1){//Contracten
          //this.profile.contracts_employment.contracts.items.refresh ++;
          //this.profile.contracts_employment.employment.items.refresh ++;
        }else if(e == 2){
            //this.profile.experience.items.refresh ++;
        }

      }

    },

    resizeEvent(e){
        
    }

  },
 
};

</script>




<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('file-upload',3)">
            <ValidationObserver ref="form" >

                <b-form @submit.prevent> 
                    <b-row class="mb-4" v-if="documents.length > 0">
                        <b-col>
                            <b-row v-for="document in documents" :key="document.file_path">
                                <b-col md="9" xl="9">
                                    <span class="app-documents-item"><div class="w-100 text-truncate">{{ document.file_name }}</div></span>
                                    <span class="app-documents-item" v-if="document.id_document_const_type == 5 || document.id_document_const_type == 6"> | {{setFormatDate(document.date_to)}}</span>
                                    <span class="app-documents-item" v-else-if="document.id_document_const_type == 4 || document.id_document_const_type == 9"> | {{setFormatDate(document.date_from)}}</span>
                                </b-col>
                                <b-col md="3" xl="3" class="text-right">
                                    <span class="app-documents-item font-weight-bolder"> {{ document.file_size_mb }} MB</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row >
                        <b-col>
                            <div class="image-upload-wrap">
                                <input class="file-upload-input" type="file" v-on:change="addFile"  accept="accept_files"/>
                                <div class="drag-text">
                                    <img src="@/assets/images/icons/download-cloud.svg" height="40px" v-if="!f_uploading" />
                                    <b-spinner v-if="f_uploading" class="spinner-margin" />
                                    <div>
                                        <small class="text-muted" >{{trans('drag-and-drop-files-jpg-png-pdf-doc-xls',180)}}</small>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #modal-footer>
                <app-button type="cancel" @click="cancelFiles"><b-spinner class="mr-1" small v-if="f_cancel === 1" />{{trans('cancel',175)}}</app-button>
                <app-button type="primary"  @click="confirmFiles" :loading="loading">
                    {{trans('add',175)}}
                </app-button>
            </template>
        </b-modal>        
    </div>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from 'moment'

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }
        }
    },

    data() {
        return {
            loading: false,
            f_cancel: 0,
            f_uploading: false,
            f_uploading_front: false,
            f_uploading_back: false,
            file_front:null,
            form_data: new FormData(),
            document_type: 20,
            documents: [],
            accept_files: {
                default: "image/png, image/jpeg, image/jpg, application/pdf, application/msword, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                type: String
            },
        };
    },

    methods: {

        addFile(e, id_type) {

            var file = e.target.files[0];
            
            if(!file.type.match('image/jp.*') && !file.type.match('image/png.*') && !file.type.match('application/pdf.*') && !file.type.match('application/vnd.openxmlformats-officedocument.wordprocessingml.document') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.*') && !file.type.match('application/msword.*')) {
                alert(this.trans('only-doc-pdf-xls-jpg-png',216));
                file = "";
                return;
            }

            
            this.form_data.append("file", file);
            this.form_data.append("file_name", file.name);
            this.form_data.append("file_size", file.size);
            this.form_data.append("file_size_mb", (file.size / 1024 / 1024).toFixed(2));
            
            this.sendFile(id_type);

        },
        sendFile(id_type) {
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            if(id_type == 1){
                this.f_uploading_front = true;
            }else{
                this.f_uploading_back = true;
            }
            this.f_uploading = true;
            axios
                .post("core/addTempFile", this.form_data, config)                
                .then((response) => {
                    this.form_data.append("temp_path", response.data);

                    var object = {};
                    this.form_data.forEach((value, key) => object[key] = value);                    
                    this.documents.push(object);
                    
                    this.form_data = new FormData();
                    this.f_uploading = false;
                    if(id_type == 1){
                        this.f_uploading_front = false;
                        this.file_front = 1
                    }else{
                        this.f_uploading_back = false;
                        this.file_back = 1
                    }
                })
                .catch(function(error) {
                    this.f_uploading = false;
                    if(id_type == 1){
                        this.f_uploading_front = false;
                        this.file_front = 1
                    }else{
                        this.f_uploading_back = false;
                        this.file_back = 1
                    }

                    console.log(error);
                });
        },
        confirmFiles() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                this.loading = true;
                axios
                    .post("planning/hours/documents/addDocuments",  {
                                id_hour_data_main: this.args.id_hour_data_main,
                                documents: JSON.stringify(this.documents),
                                filters: JSON.stringify(this.args.filters),
                            })
                    .then((response) => {

                        this.cReturn.documents.items = response.data.items;
                        this.$emit('update:result', 1);
                    })
                    .catch((error) => {
                        console.error(`error: ${error}`);
                    })
                    .finally(()=> {
                        this.loading = false;
                        this.computedShow = false;
                    })
            })
        },
        cancelFiles() {            
            if(this.documents.length > 0) {
                this.f_cancel = 1;
                axios
                .post("people/profile/documents/removeTemp",  {
                            documents: JSON.stringify(this.documents),
                        })
                .then((response) => {
                    this.f_cancel = 0;
                    this.closeModal();
                })
                .catch(function(error) {
                    this.f_cancel = 0;
                    console.log(error);
                });
            }
            else {
                this.closeModal();
            }
        },        
        closeModal() {
            this.documents = [];
            this.$emit("update:show", false);
        },

        setFormatDate(dateValue) {
           return moment(dateValue).format('DD-MM-YYYY');
        },
    },
};
</script>

<style scoped>
.image-upload-wrap {
    border: 1px dashed #BBE7FA;
    border-radius: 5px;
    position: relative;
    transition: 1s;
}

.image-dropping,
.image-upload-wrap:hover {
    background-color: #f8fcfe;
}

.drag-text {
    text-align: center;
    padding: 20px 10px;
}

.drag-text p {
    color: #000000;
    font-size: 12px;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}
.spinner-margin{
    margin-top: 4px; 
    margin-bottom:6px
}
</style>

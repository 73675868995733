<template>
    <div>
        <b-card no-body class="app-card-profile mb-3">
               <b-row class="app-card-header">
                    <b-col >
                        <div class="app-card-profile-title">
                           {{trans('general-data',3)}}
                        </div>
                    </b-col>
                </b-row>
                        
                <b-row class="mt-3">
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('hr-created',272)">
                            <span class="app-local-bold-text"> {{profile.hour_data.hour_entry_date}} </span>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('planning-last-modification',209)">
                            <div class="d-flex align-items-center">
                                <span class="app-local-bold-text mr-2" v-b-tooltip.hover.top :title="profile.hour_data.last_action"> {{ profile.hour_data.id_hour_data_history != null ? profile.hour_data.last_action_user_name : '-' }} </span>
                                <settings-users-source-badge-component :type="profile.hour_data.last_action_source"/>
                            </div>  
                        </b-form-group>
                    </b-col>

                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('notes-flexworker',209)">
                           <span class="app-local-bold-text"> {{ ((profile.hour_data.person_description == '' || profile.hour_data.person_description == null) ? '-' : profile.hour_data.person_description) }}</span>  
                        </b-form-group>
                    </b-col>
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('notes-client',209)">
                           <span class="app-local-bold-text"> {{ ((profile.hour_data.client_description == '' || profile.hour_data.client_description == null) ? '-' : profile.hour_data.client_description) }} </span>
                        </b-form-group>
                    </b-col>
                </b-row>
           
        </b-card>

        <b-card no-body class="app-card-profile">
               <b-row class="app-card-header">
                    <b-col >
                        <div class="app-card-profile-title">
                           {{trans('hr-details-of-declaration-services',272)}}
                        </div>
                    </b-col>
                </b-row>

            <b-row class="p-3">
                <b-col cols="3" class="pr-4 app-local-right-line">
                <span class="col-list-label"><b>{{ trans('declarations',209) }}</b></span>

                    <b-row>
                        <b-col cols="6">
                            <span class="col-list-label">{{ trans('hours-worked',207) }}</span>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <span class="col-list-label">€ {{(JSON.parse(profile.hour_data.hours_json).total_hours_value).replace('.',',')}}</span>
                        </b-col>
                    </b-row>
                    
                    <b-row v-for="item in JSON.parse(profile.hour_data.hours_json).declarations" :key="item.name">
                        <b-col cols="8" v-if="![3,4,9].includes(item.id_placement_const_component)">
                            <span class="col-list-label" v-overflow-tooltip>{{item.component_type_name}}</span>
                        </b-col>
                        <b-col cols="8" v-if="[3,4,9].includes(item.id_placement_const_component)">
                            <span class="col-list-label" v-overflow-tooltip>{{item.name}}</span>
                        </b-col>
                        <b-col cols="4" class="text-right" v-if="![3,4,9].includes(item.id_placement_const_component)">
                            <span class="col-list-label">€ {{parseFloat(item.amount).toFixed(2).replace('.',',')}}</span>
                        </b-col>
                        <b-col cols="4" class="text-right" v-if="[3,4].includes(item.id_placement_const_component)">
                            <span class="col-list-label">€ {{(parseFloat(item.person_value * item.distance).toFixed(2)).replace('.',',')}}</span>
                        </b-col>
                        <b-col cols="4" class="text-right" v-if="[9].includes(item.id_placement_const_component)">
                            <span class="col-list-label">€ {{parseFloat(item.person_value).toFixed(2).replace('.',',')}}</span>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="text-right">
                            <b>Totaal</b> <span class="col-list-label"><b>€ {{(JSON.parse(profile.hour_data.hours_json).total_value).replace('.',',')}}</b></span>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="3" class="pr-4 pl-4 app-local-right-line">
                    <span class="col-list-label"><b>{{ trans('planned-hours',209) }}</b></span>
                    <b-row v-for="item in JSON.parse(profile.hour_data.plan_hours_json).hours" :key="item.time_from">
                        <b-col cols="6" v-if="item.type != 2">
                            <span class="col-list-label">{{item.time_from}} - {{item.time_to}}</span>
                        </b-col>
                        <b-col cols="6" v-else>
                            <span class="col-list-label">{{ trans('sh-pause',215) }}</span>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <span class="col-list-label">{{parseInt(item.percent)+100}} %</span>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="3" class="pr-4 pl-4 app-local-right-line">
                    <span class="col-list-label"><b>{{ trans('real-hours',209) }}</b></span>
                    <b-row v-for="item in JSON.parse(profile.hour_data.hours_json).hours" :key="item.time_from">
                        <b-col cols="6" v-if="item.type != 2">
                            <span class="col-list-label">{{item.time_from}} - {{item.time_to}}</span>
                        </b-col>
                        <b-col cols="6" v-else>
                            <span class="col-list-label">{{ trans('sh-pause',215) }}</span>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <span class="col-list-label">{{parseInt(item.percent)+100}} %</span>
                        </b-col>
                    </b-row>
            
                </b-col>

                <b-col cols="3" class="pr-4 pl-4 ">
                    <span class="col-list-label"><b>{{ trans('travel-costs',192) }}</b></span>
                    <b-row v-for="item in JSON.parse(profile.hour_data.hours_json).declarations" :key="item.name">
                        <b-col cols="8" v-if="[3,4].includes(item.id_placement_const_component)">
                            <span class="col-list-label" v-overflow-tooltip>{{item.name}}</span>
                        </b-col>
                        <b-col cols="4" class="text-right" v-if="[3,4].includes(item.id_placement_const_component)">
                            <span class="col-list-label">{{ (item.distance == null ? ' - ' : item.distance + ' KM')}}</span>
                        </b-col>
                    </b-row>
            
                </b-col>
            </b-row>
            <b-row class="mt-2 mb-1">

            
            </b-row>
           
        </b-card>
    </div>
</template>
<script>

import SettingsUsersSourceBadgeComponent from '@app/Settings/Users/Components/SettingsUsersSourceBadgeComponent.vue';

export default {

    props:["profile"],

    components: {        
        SettingsUsersSourceBadgeComponent
    },

    data(){
        return{
        }
    },

};
</script>

<style scoped>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
    font-size: 14px !important;
}

.app-local-text{
    color:#174066;
    font-size: 12px !important;
}

.tooltip{
    opacity: 1 !important;
}

.app-local-right-line {
        border-right: 1px solid #e6e6e6 !important;  
    }
</style>